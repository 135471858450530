* {
  font-weight: 400;
  font-size: 13px;
  font-family: sans-serif;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.login-bg {
  background-size: cover;
  width: 100%;
  border-radius: 50px;
  /* height: 100%; */
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  height: 40px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid transparent;
  border-top: 4px solid transparent;
  border-image: linear-gradient(brown, #ff0000, #00ff00);
  border-image-slice: 1;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-date-picker {
  width: 250px;
  height: 5.2vh;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out;
}

.txn-date-picker {
  width: 187px;
  height: 5.4vh;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out;
}

.serviceTxnCard {
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  background-color: #ebd5d5;
  display: flex;
  max-width: 332px;
  flex-direction: column;
  white-space: nowrap;
  padding: 16px;
}

.txnHeading {
  color: #000;
  align-self: center;
  font: 700 15px Inter, sans-serif;
}

.txnStatus {
  display: block;
  margin-top: 24px;
  gap: 0px;
  font-size: 12px;
  font-weight: 600;
}

.txnStatus2 {
  display: flex;
  gap: 8px;
  color: #0e543b;
  flex: 1;
  padding: 8px;
}

.txnStatus3 {
  font-family: Inter, sans-serif;
  font-weight: bold;
}

.txnStatus4 {
  font-family: Inter, sans-serif;
  font-weight: bold;

}

.txnStatus5 {
  display: flex;
  gap: 8px;
  color: #f5501f;
  flex: 1;
  padding: 8px;

}

.txnStatus6 {
  font-family: Inter, sans-serif;
}

.txnStatus7 {
  font-family: Inter, sans-serif;
}

.txnStatus8 {
  display: flex;
  gap: 8px;
  color: #f50f00;
  flex: 1;
  padding: 8px;
}

.txnStatus9 {
  font-family: Inter, sans-serif;
}

.txnStatus10 {
  font-family: Inter, sans-serif;
}


.div {
  justify-content: center;
  border-radius: 10px;
  background: linear-gradient(45deg, #fff, #cbe5da);
  box-shadow: 0px 4px 4px 0px var(--ShadowColor, #bababa);
  display: flex;
  /* max-width: 232px; */
  flex-direction: column;
  font-size: 12px;
  padding: 16px;
  /* background-color: #02353C; */
}

.div-sec {
  justify-content: center;
  border-radius: 10px;
  background: linear-gradient(103deg, #fff 0%, #F57C00 100%);
  box-shadow: 0px 4px 4px 0px var(--ShadowColor, #bababa);
  display: flex;
  max-width: 232px;
  flex-direction: column;
  font-size: 12px;
  padding: 16px;
}

.div-2 {
  justify-content: space-between;
  display: flex;
  gap: 20px;
  color: #777;
  white-space: nowrap;
}

.div-3 {
  display: flex;
  flex-direction: column;
}

.div-4 {
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: #02353C;
}

.div-5 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  margin-top: 9px;
  color: #02353C;

}

.img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  margin: auto 0;
  margin-top: 0;
}

.div-6 {
  justify-content: space-between;
  display: flex;
  margin-top: 16px;
  gap: 20px;
}

.div-7 {
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 4px;
  background-color: #2EAF7D;
  color: var(--TintColor, #fff);
  font-weight: 700;
  white-space: nowrap;
  padding: 4px 16px;
  text-decoration: none;
}

.div-8 {
  color: #02353C;
  font-family: Inter, sans-serif;
  font-weight: 400;
  margin: auto 0;
}

.form-control {
  height: 38px;
}


@media (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
  }

  .App-header {
    min-height: 80vh;
  }

  .header {
    width: 450px;
  }

  .sidebar.expanded {
    position: absolute;
  }

}